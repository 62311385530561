<template>
  <div :ref="menuRef" class="menu">
    <div class="activator">
      <slot name="activator" :props="activatorProps"></slot>
    </div>
    <teleport to="body">
      <transition :name="transition">
        <div
          v-if="isMenuVisible"
          ref="menuContent"
          class="menu-content"
          :style="[menuPosition, { width }, { minWidth }, { maxWidth }]"
          @click.stop="handleClickInside"
        >
          <slot />
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { useBeforeRouteChange } from '@/composables/useBeforeRouteChange'
import { randomString } from '@/utils/string-util.js'

/**
The BaseMenu component is used with a scoped slot for the activator which is used to define the trigger element that will activate the menu.

@example
  <BaseMenu location="bottom">
    <template #activator="{ props }">
      <BaseButton v-bind="props"></BaseButton>
    </template>
  </BaseMenu>

  The BaseButton inside the activator slot will be used as the trigger element which opens the menu when clicked.
 */
export default {
  props: {
    /**
     * Represents the state of the menu (open or closed).
     * It's a Boolean value where `true` means the menu is open and `false` means it's closed.
     * If not provided, the default value is `false`, meaning the menu is closed by default.
     *
     * @prop {Boolean} modelValue - The state of the menu.
     */
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    location: {
      type: String,
      default: 'bottom',
      validator: (value) => {
        return ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1
      }
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: 'max-content'
    },
    /**
     * Transition effect for the menu.
     * It can be either 'fade' or 'slide'.
     * If not provided, the default value is 'fade'.
     *
     * @prop {String} transition - The transition effect for the menu.
     */
    transition: {
      type: String,
      required: false,
      default: 'fade',
      validator: (value) => {
        return ['fade', 'slide', 'expand'].indexOf(value) !== -1
      }
    }
  },
  emits: ['update:modelValue'],
  setup () {
    const { setBeforeRouteChangeCallback } = useBeforeRouteChange()
    return { setBeforeRouteChangeCallback }
  },
  data () {
    return {
      isMenuVisible: false,
      menuPosition: {},
      viewportWidth: 0,
      viewportHeight: 0,
      minWidth: null,
      menuRef: null
    }
  },
  computed: {
    activatorProps () {
      return {
        onClick: this.toggleMenu
      }
    },
    maxWidth () {
      if (!this.viewportWidth) return null
      const marginOffset = 10
      return `${(this.viewportWidth - marginOffset)}px`
    }
  },
  watch: {
    modelValue (val) {
      if (this.isMenuVisible && !val) {
        this.closeMenu()
      }
    }
  },
  created () {
    this.menuRef = `menu-${randomString()}`
  },
  mounted () {
    this.viewportWidth = window.innerWidth
    this.viewportHeight = window.innerHeight

    if (this.modelValue) this.isMenuVisible = true
    window.addEventListener('click', this.closeMenuOnClickOutside)
    window.addEventListener('resize', this.updateViewportDimensions)
    window.addEventListener('scroll', this.updateOnScroll)
    this.setBeforeRouteChangeCallback(this.onBeforeRouteChange)
    this.$emitter.on('closeOtherMenu', this.closeOtherMenuIfOpened)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.closeMenuOnClickOutside)
    window.removeEventListener('resize', this.updateViewportDimensions)
    window.removeEventListener('scroll', this.updateOnScroll)
    this.$emitter.off('closeOtherMenu', this.closeOtherMenuIfOpened)
  },
  methods: {
    async onBeforeRouteChange (to, from, next) {
      // Close the menu if it is open when navigating to a new route
      if (this.isMenuVisible) {
        this.closeMenu()
      }
      next()
    },
    updateViewportDimensions () {
      this.viewportWidth = window.innerWidth
      this.viewportHeight = window.innerHeight
      if (this.isMenuVisible) {
        this.adjustPosition()
      }
    },
    async updateOnScroll () {
      if (this.isMenuVisible) {
        await this.$nextTick()
        this.adjustPosition()
      }
    },
    async toggleMenu (e) {
      e.stopPropagation()
      this.$emitter.emit('closeOtherMenu', this.menuRef)
      this.isMenuVisible = !this.isMenuVisible
      if (this.isMenuVisible) {
        this.$emit('update:modelValue', true)
        // Ensure the DOM has been updated before adjusting the dropdown position
        await this.$nextTick()
        this.adjustPosition()
      } else {
        this.$emit('update:modelValue', false)
      }
    },
    closeMenuOnClickOutside (event) {
      if (!this.$refs[this.menuRef].contains(event.target)) {
        this.closeMenu()
      }
    },
    closeOtherMenuIfOpened (menuRef) {
      // This ensures that only the clicked menu remains open, while all others are closed.
      if (menuRef !== this.menuRef && this.isMenuVisible) {
        this.closeMenu()
      }
    },
    handleClickInside (event) {
      if (this.closeOnContentClick) {
        // When an input selector is opened inside the menu, the clientX and clientY are 0
        if (!event.clientX || !event.clientY) return

        // The `event.target.type` is used to check if the click event is on an element (e.g, dropdown) inside a menu.
        // `event.target.type` is not `undefined` for such elements and
        // we don't want to close the menu when user interacts with them.
        if (event.target.type) return
        this.closeMenu()
      }
    },
    closeMenu () {
      this.isMenuVisible = false
      this.$emit('update:modelValue', false)
    },
    adjustPosition () {
      const activatorRect = this.$refs[this.menuRef].getBoundingClientRect()
      const menuContentRect = this.$refs.menuContent.getBoundingClientRect()
      const positionStyle = {}

      // Adjust the position of the menu based on the `location` prop and the position of the activator element
      // But if the menu is too close to the edge of the viewport, or if it overflows, then adjust the position accordingly.
      // Example if the menu is at the bottom, and the activator is towards the bottom of the viewport, then the menu is be displayed above the activator.
      // The `top` and `left` properties define the position of the top-left corner of the menu on the screen.
      positionStyle.top = this.calculateTopPosition(activatorRect, menuContentRect, this.location)
      positionStyle.left = this.calculateLeftPosition(activatorRect, menuContentRect, this.location)

      this.minWidth = `${activatorRect.width}px`
      this.menuPosition = positionStyle
    },
    calculateTopPosition (activatorRect, menuContentRect, location) {
      const activatorMenuSpacing = 5

      const topMenuPosition = activatorRect.top - menuContentRect.height - activatorMenuSpacing
      const bottomMenuPosition = activatorRect.bottom + activatorMenuSpacing

      const isMenuOverflowingViewport = activatorRect.bottom + menuContentRect.height > this.viewportHeight
      const isMenuDisplayedAboveScreen = activatorRect.top - menuContentRect.height < 0

      let topPosition
      if (location === 'bottom') {
        topPosition = `${bottomMenuPosition}px`
        if (isMenuOverflowingViewport) {
          topPosition = `${topMenuPosition}px`
        }
      } else if (location === 'top') {
        topPosition = `${topMenuPosition}px`
        if (isMenuDisplayedAboveScreen) {
          topPosition = `${bottomMenuPosition}px`
        }
      } else {
        if (isMenuOverflowingViewport) {
          topPosition = `${topMenuPosition}px`
        } else {
          topPosition = `${activatorRect.top}px`
        }
      }
      return topPosition
    },
    calculateLeftPosition (activatorRect, menuContentRect, location) {
      const activatorMenuSpacing = 5

      const leftMenuPosition = activatorRect.left - menuContentRect.width - activatorMenuSpacing
      const rightMenuPosition = activatorRect.right + activatorMenuSpacing

      const isMenuCutOffOnLeft = activatorRect.left - menuContentRect.width - activatorMenuSpacing < 0
      const isMenuOverflowingOnRight =
          activatorRect.right + menuContentRect.width + activatorMenuSpacing > this.viewportWidth

      let leftPosition
      if (location === 'left') {
        leftPosition = `${leftMenuPosition}px`
        // Check if the menu is cut off on the left side
        if (isMenuCutOffOnLeft) {
          // Before flipping the menu to the right side, check if there is enough space on the right side.
          // If not, then start the menu from the left edge of the viewport.
          if (isMenuOverflowingOnRight) {
            leftPosition = `${activatorMenuSpacing}px`
          } else {
            // Flip the menu to the right side of the activator element
            leftPosition = `${rightMenuPosition}px`
          }
        }
      } else if (location === 'right') {
        leftPosition = `${rightMenuPosition}px`
        // Check if the menu goes off the screen on the right side
        if (isMenuOverflowingOnRight) {
          // Before flipping the menu to the left side, check if there is enough space on the left side.
          // If not, then adjust the positioning of the menu so that it stretches towards the viewport on right.
          if (isMenuCutOffOnLeft) {
            leftPosition = `${this.viewportWidth - activatorMenuSpacing - menuContentRect.width}px`
          } else {
            // Flip the menu to the left side of the activator element
            leftPosition = `${leftMenuPosition}px`
          }
        } else {
          leftPosition = `${activatorRect.left}px`
        }
      } else {
        leftPosition = `${activatorRect.left}px`
        if (isMenuOverflowingOnRight) {
          leftPosition = `${activatorRect.left - menuContentRect.width + activatorRect.width}px`
        }
      }
      return leftPosition
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
  display: inline-block;
}

.menu-content {
  position: fixed;
  width: auto;
  background-color: white;
  border-radius: 8px;
  overflow: auto;
  font-family: "Open Sans";
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 102;
}

/* fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* slide animation */
.slide-enter-active,
.slide-leave-active {
  transition: opacity transform 0.4s ease-in-out;
}

.slide-enter-from, .slide-leave-to {
  transform: scaleY(0);
}

/* expand animation */
.expand-enter-active,
.expand-leave-active {
  transition: all 0.15s ease-in-out;
}

.expand-enter-from,
.expand-leave-to {
  opacity: 0;
  transform: translateY(-5%) scale(1);
}
</style>

<template>
  <div class="select-container">
    <select
      v-model="value"
      :id="id"
      :autofocus="autofocus"
      :disabled="disabled"
      :required="required"
      :class="[isBorderlessDropdown ? 'select-borderless icon-right-borderless' : 'select icon-right']"
      :data-cy="dataCy"
      data-testid="base-select-input"
      @focus="$emit('focus')"
    >
      <option
        v-if="placeholder"
        selected
        disabled
        :value="placeholder"
        class="disabled"
      >
        {{ placeholder }}
      </option>

      <!-- Slot to add <optgroup> element with a <select> -->
      <slot name="optgroup"></slot>
      <option
        v-for="option in formattedOptions"
        :key="option.text"
        :value="option[valueField]"
        :disabled="option.disabled"
        :class="{ disabled: option.disabled, bold: option.bold }"
      >
        {{ option[textField] }}
      </option>
    </select>
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
  </div>
</template>

<script>
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation.js'

export default {
  components: { InputErrorBucket },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object],
      required: false
    },
    rules: {
      type: Function,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [String, Boolean],
      required: false,
      default: 'auto'
    },
    id: {
      type: String,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    },
    /**
     * The <option> elements to be populated within the <select>.
     * Recognized fields are:
     * {
     *   text: String,
     *   value: Any,
     *   disabled: Boolean,
     *   selected: Boolean,
     *   bold: Boolean
     * }
     * NOTE: An object that doesn't fit the above fields such as
     * ` { label: 'foo', option: 'bar' } `
     * may be passed in as well but would need to set the below
     * `value-field` and `text-field` props to be recognized.
     */
    options: {
      type: Array,
      default: () => []
    },
    /**
     * A text field to replace the above recognized `text` for an option
     */
    textField: {
      type: String,
      required: false,
      default: 'text'
    },
    /**
     * A value field to replace the above recognized `value` for an option
     */
    valueField: {
      type: String,
      required: false,
      default: 'value'
    },
    isBorderlessDropdown: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'focus'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    value: {
      get () {
        if (this.hasModelValue) return this.modelValue
        if (this.placeholder) return this.placeholder
        return this.modelValue
      },
      async set (value) {
        this.$emit('update:modelValue', value)
        await this.$nextTick()
        this.validate(value)
      }
    },
    /**
     * Allows options to be an array of strings
     */
    formattedOptions () {
      return this.options.map(option => {
        if (typeof option === 'string') {
          return { text: option, value: option }
        } else {
          return option
        }
      })
    },
    hasModelValue () {
      if (this.modelValue !== null && typeof this.modelValue === 'object') {
        return Object.keys(this.modelValue).length !== 0
      }
      return !!this.modelValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

/* stylelint-disable property-no-vendor-prefix */

.select {
  /* copy bootstrap-vue's styling */
  display: inline-block;
  width: 100%;
  height: 38px;
  padding: 6px 5px;
  font-size: 16px;
  line-height: $line-height-desktop-body;
  color: $neutral-typography-dark;
  vertical-align: middle;
  background: $surface-color url("~@/assets/icons/input-select-arrow.svg") no-repeat;
  border: 1px solid $border-grey;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 8px;
  margin-bottom: 4px;

  /* highlight upon focus transition */
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: $primary-digital-teal-default;
    outline: 0;
  }

  &:hover {
    border-color: $primary-digital-teal-default;
    outline: 0;
  }

  &:disabled {
    border-color: $bg-light-grey;
    color: $neutral-placeholder-text;
    opacity: 1;
    outline: 0;
    background: $bg-light-grey url("~@/assets/icons/input-select-arrow-disabled.svg") no-repeat;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-style: none;
  color: inherit;
  text-transform: none;
  font: inherit;
  word-wrap: normal;
}

.disabled {
  color: $neutral-typography-medium;
}

.bold {
  font-family: "Open Sans Semi-Bold";
}

.icon-right {
  background: $surface-color url("~@/assets/icons/input-select-arrow.svg") no-repeat;
  background-position: right 12px center !important;
  padding-left: 8px;
  padding-right: 32px;
}

.select-container {
  display: flex;
  flex-direction: column;
}

.select-borderless {
  color: $primary-digital-teal-default;
  font-family: "Open Sans Semi-Bold";
  border-radius: 0;
  border: 0px;
  padding: 2px;
  height: 24px;
  padding-right: 30px;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-top: 5px;

  &:focus {
    border-bottom: 1px solid $primary-digital-teal-default;
    outline: 0;
  }
}

.icon-right-borderless {
  background-image: url("~@/assets/icons/input-select-arrow-teal.svg");
  background-position: right 12px top 60%;
  background-repeat: no-repeat;
}
</style>

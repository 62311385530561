export class CameraAccessDeniedError extends Error {
  constructor (message) {
    super(message)
    this.name = 'CameraAccessDeniedError'
  }
}

export class MissingCameraError extends Error {
  constructor (message) {
    super(message)
    this.name = 'MissingCameraError'
  }
}

export class EnumerateDeviceError extends Error {
  constructor (message) {
    super(message)
    this.name = 'EnumerateDeviceError'
  }
}

export class HIDSecurityError extends Error {
  constructor (message) {
    super(message)
    this.name = 'HIDSecurityError'
  }
}

export class WsStreamError extends Error {
  constructor (message) {
    super(message)
    this.name = 'WsStreamError'
  }
}

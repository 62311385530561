<template>
  <div class="simsei-application">
    <slot />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/default.scss";

@include font-face("@/assets/font");

body {
  background-color: $background-color;
}

.simsei-application {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
}
</style>

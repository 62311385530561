import Stomp from 'webstomp-client'
import log from '@/residency/utils/log'
import RecordRTC from 'recordrtc'
import { simseiApi, dispatchCheckAccessToken } from '@/residency/app-props'
import { buildBufferProxy, retransmitMissingMessages, getBufferSize, resetBuffer } from '@/residency/websocket/msg-buffer'
import { useProgramStore } from '@/residency/stores/program'
import { useAuthStore } from '@/residency/stores/auth'
import { useMeStore } from '@/residency/stores/me'
import { WsStreamError } from './camera-errors'
import { getVideoExtension } from '@/residency/components/video/video-record-utils'

/**
 * This class is responsible for managing the state for uploading a recorded video stream. It handles
 * the websocket connection, creating the stream on the backend and uploading the video in blobs.
 * A stream is a single video with a given type (LAPAROSCOPE or AI_SETUP) and is represented by a
 * VideoStreamEntity on the backend.
 */
export default class VideoRecordStream {
  // Stream state
  streamType
  recordId
  deviceId
  id = null
  ws = null
  videoLength = 0
  fullVideoBlob = null
  totalByteSize = 0

  // Record state
  stoppedRecording = false
  reconnecting = false
  streamInterrupted = false

  // Upload state
  uploading = false
  backgroundUploading = false
  cancelingVideoUpload = false
  queueIndex = 0
  uploadQueue = []
  byteCount = 0
  segmentId = 0
  remainingBufferSize = 0
  finalBlobReceiptIds = {}
  sentFinalBlob = false
  uploadPercent = 0

  uploadChecker

  constructor (streamType, deviceId, asmtId) {
    this.streamType = streamType
    this.recordId = asmtId
    this.deviceId = deviceId
  }

  destroy () {
    if (this.ws) {
      this.ws.disconnect()
    }
    if (this.uploadChecker) {
      clearInterval(this.uploadChecker)
    }
  }

  async startStream ({ frameRate, mimeType, shouldGenerateThumbnails }) {
    let params = `recordId=${this.recordId}`
    params += `&streamType=${this.streamType}`
    params += `&frameRate=${frameRate}`
    params += `&deviceId=${this.deviceId}`
    params += `&mimeType=${mimeType}`
    params += `&needsThumbnails=${shouldGenerateThumbnails}`
    const resp = await simseiApi.post(`/video/start-stream?${params}`)
    const streamId = resp.data

    await dispatchCheckAccessToken()
    resetBuffer() // Reset buffer to prevent old data from being sent on reconnect

    try {
      this.id = streamId
      this.ws = await this.connectSocket(true)
      this.byteCount = 0
    } catch (err) {
      throw new WsStreamError(err)
    }
  }

  /**
   * Set stoppedRecording on the stream and upload final blob
   */
  async endStream (fullVideoBlob) {
    this.fullVideoBlob = fullVideoBlob
    this.stoppedRecording = true
    this.uploadBlob()
  }

  async endStreamAbruptly () {
    this.cancelingVideoUpload = true
    this.stoppedRecording = true
    this.sendFinalBlob()
  }

  /**
   * Cancel the video recording
   */
  cancelRecording (videoType) {
    this.backgroundUploading = false
    simseiApi.delete(`/video/${videoType}/cancel/${this.recordId}`)
    this.ws.disconnect()
  }

  pauseRecordUpload () {
    this.cancelingVideoUpload = true
  }

  resumeRecordUpload () {
    this.cancelingVideoUpload = false
    this.uploadBlob(true)
  }

  downloadRecordedVideo (videoTitle) {
    const fileExt = getVideoExtension(this.fullVideoBlob.type)
    RecordRTC.invokeSaveAsDialog(this.fullVideoBlob, `${videoTitle}.${fileExt}`)
  }

  startUploadChecker () {
    this.backgroundUploading = true
    if (this.uploadChecker) clearInterval(this.uploadChecker)

    // Only check the upload percentage for the laparoscope stream
    this.uploadChecker = setInterval(() => {
      let wsEnded = true
      let currentBufferedAmount = 0
      // Check if both websocket connections have finished streaming
      const socket = this.ws.ws

      if (socket.readyState !== WebSocket.CLOSED || this.reconnecting) wsEnded = false
      currentBufferedAmount += socket.bufferedAmount

      if (wsEnded) {
        clearInterval(this.uploadChecker)
        this.backgroundUploading = false
        this.uploadPercent = 1
      } else if (this.totalByteSize !== 0) {
        this.uploadPercent = 1 - (this.remainingBufferSize + currentBufferedAmount) / this.totalByteSize
      }
    }, 500)
  }

  // This method is called by RecordRTC to upload the video blob in chunks.
  timeSliceUpload (blob) {
    const maxBlobSize = 1835008 // 1.75MB (1024^2 * 1.75)
    const subBlobs = blob.size / maxBlobSize
    if (subBlobs <= 1) {
      // blob is less than or equal to maxBlobSize, so just upload it
      this.queueBlob(blob)
    } else {
      // Split blob into smaller blobs
      let start = 0
      let end = maxBlobSize
      for (let i = 0; i < subBlobs; i++) {
        this.queueBlob(blob.slice(start, end))
        start = end
        end += maxBlobSize
      }
    }
  }

  getStreamType () {
    return this.streamType
  }

  getFullVideoBlob () {
    return this.fullVideoBlob
  }

  setVideoLength (length) {
    this.videoLength = length
  }

  queueBlob (blobInfo) {
    this.uploadQueue[this.segmentId] = blobInfo
    this.uploadBlob()
    this.totalByteSize += blobInfo.size
    this.remainingBufferSize += blobInfo.size
    this.segmentId++
  }

  async uploadBlob (force) {
    if (this.cancelingVideoUpload) {
      return
    }

    if (!this.ws) {
      log.error(`Stream websocket was null while uploading a blob for streamType ${this.streamType}`)
      return
    }

    // This ensures that the blob won't be uploaded twice
    if (!force && this.uploading) {
      return
    }

    // Stop uploading if ws disconnected
    if (!this.ws.connected) {
      this.uploading = false
      log.error('Websocket connection disconnected while uploading')
      return
    }

    this.uploading = true
    const blobToUpload = this.uploadQueue[this.queueIndex]

    if (!blobToUpload) {
      if (this.stoppedRecording) {
        this.sendFinalBlob({ attempt: 0 })
        return
      } else {
        this.uploading = false
        return
      }
    }

    try {
      // per this example: https://websockets.spec.whatwg.org/buffered-amount-example
      // this is the number of bytes of data that have been queued using calls to send()
      // but not yet transmitted to the network.
      // Therefore, by only sending messages when bufferedAmount is zero, we can ensure that
      // we send data about every 100ms OR as fast as the network can handle it.
      if (this.ws.ws.bufferedAmount === 0) {
        // Converts blob into a base64 string
        const arrayBuffer = await blobToUpload.arrayBuffer()
        const buf = Buffer.from(arrayBuffer)
        const base64 = buf.toString('base64')

        const { programId } = useProgramStore()

        // Constructs json and send to webservice via websockets
        const data = JSON.stringify({
          id: this.id,
          segment: this.queueIndex,
          base64: base64,
          stream: this.streamType,
          done: false,
          programId: programId
        })
        if (this.ws.ws.readyState === WebSocket.OPEN) {
          this.ws.send('/app/video', data)
          this.remainingBufferSize = this.remainingBufferSize - arrayBuffer.byteLength

          delete this.uploadQueue[this.queueIndex]
          this.queueIndex++
        }

        if (this.sentFinalBlob) {
          log.warn('Upload called after final blob sent')
        }
      }
      setTimeout(() => this.uploadBlob(true), 100)
    } catch (err) {
      log.error(err)
      this.uploading = false
      this.retryUpload()
    }
  }

  async sendFinalBlob () {
    if ((!this.cancelingVideoUpload && this.hasBufferedData()) ||
        this.ws.ws.readyState !== WebSocket.OPEN) {
      // let the buffer and upload queue clear before sending the final packet
      setTimeout(() => this.sendFinalBlob(), 100)
      return
    }
    try {
      const { programId } = useProgramStore()
      const data = {
        id: this.id,
        done: true,
        streamInterrupted: this.streamInterrupted,
        totalByteCount: this.byteCount,
        stream: this.streamType,
        programId: programId
      }
      // Wait for the final blob to be processed by waiting for the receipt
      // For more info, see this wiki page:
      // https://gitlab.simseidev.com/web-development/simsei/deployment-setup/-/wikis/WebSocket-heatbeat-and-receipts
      const expectedReceiptId = `final-blob-${this.id}`
      this.addReceiptId(expectedReceiptId)
      this.ws.onreceipt = (frame) => {
        this.uploading = false
        this.backgroundUploading = false
        const processedReceiptId = frame.headers['receipt-id']
        this.removeReceiptId(processedReceiptId)
      }
      this.attemptSendFinalBlob(data, expectedReceiptId, 0)
    } catch (err) {
      log.error(err)
      this.uploading = false
      this.retryUpload()
    }
  }

  retryUpload () {
    log.info('Retrying to upload failed blob')
    setTimeout(() => {
      this.uploadBlob()
    }, 1000)
  }

  async attemptSendFinalBlob (data, expectedReceiptId, attempt) {
    try {
      // determine if we have tried to resend the final blob too many times
      if (this.finalBlobReceiptIds[expectedReceiptId] && attempt >= 6) {
        log.error(`Failed to send final blob to webservice after 6 attempts for stream ${this.id}.`)
        this.removeReceiptId(expectedReceiptId)
        return
      }

      if (this.ws.ws.readyState === WebSocket.OPEN) {
        this.ws.send('/app/video', JSON.stringify(data), {
          'receipt': expectedReceiptId
        })
        this.sentFinalBlob = true
      } else {
        log.warn(`Attempted to send final blob to webservice but websocket is not open for stream ${this.id}`)
      }
      if (!this.cancelingVideoUpload) {
        // schedule callback to retry final blob send if we don't get a receipt
        // after some time
        setTimeout(() => {
          if (this.finalBlobReceiptIds[expectedReceiptId]) {
            this.attemptSendFinalBlob(data, expectedReceiptId, ++attempt)
          }
        }, 5000)
      }
    } catch (err) {
      log.error(err)
      this.uploading = false
      this.retryUpload()
    }
  }

  hasBufferedData () {
    // Websocket has data waiting to be put on the wire
    if (this.ws.ws.bufferedAmount !== 0) return true
    // We have not sent all the intended video blobs.
    if (this.queueIndex !== this.uploadQueue.length) return true
    // Not all data has been acked, this may mean we have to retransmit those blobs.
    // Therefore, those blobs can be considered queued.
    if (getBufferSize(this.streamType) > 0) return true
    return false
  }

  addReceiptId (receiptId) {
    // record the number of times we've tried to send the final blob
    if (!this.finalBlobReceiptIds[receiptId]) {
      this.finalBlobReceiptIds[receiptId] = {
        attempts: 0
      }
    } else {
      let attempts = this.finalBlobReceiptIds[receiptId].attempts
      this.finalBlobReceiptIds[receiptId] = {
        attempts: ++attempts
      }
    }
  }

  removeReceiptId (receiptId) {
    delete this.finalBlobReceiptIds[receiptId]
  }

  /**
   * Initializes a websocket connection.
   * Websocket will retry to connect if webservice goes down or their internet is cut.
   */
  connectSocket (initial) {
    const authStore = useAuthStore()
    const meStore = useMeStore()

    const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_API)

    // Heartbeat of 25 seconds "is in line with the following IETF recommendation for public Internet applications."
    // https://docs.spring.io/spring-framework/docs/4.1.0.RC1/spring-framework-reference/html/websocket.html
    let ws = Stomp.over(socket, { debug: false, heartbeat: { incoming: 25000, outgoing: 25000 } })
    // Wrap websocket with buffer proxy to handle message buffering/resending
    ws = buildBufferProxy(ws, this.streamType)

    return new Promise((resolve, reject) => {
      ws.connect(
        { Authorization: authStore.tokenInfo.accessToken },
        () => {
          ws.streamType = this.streamType
          resolve(ws)
          log.info(`Video WS (${this.streamType}) has connected for user ${meStore.id}`)
          if (!initial) {
            // Retry blob upload only if websocket disconnected previously
            console.info('Video WS has reconnected!')
            this.ws = ws
            this.reconnecting = false
            this.uploadBlob()
          }

          // Retransmit any messages that were buffered and not sent
          const numOfRetransmittedMessages = retransmitMissingMessages(ws, this.streamType)
          if (numOfRetransmittedMessages > 0) {
            log.info(`Retransmitted ${numOfRetransmittedMessages} buffered messages`)
          }
        },
        (err) => {
          reject(err)
          this.reconnecting = true
          this.streamInterrupted = true
          log.warn(`Video WS (${this.streamType}) has disconnected for user ${meStore.id}`)
          // Reconnect websocket after 1s
          setTimeout(() => {
            console.info(`Video WS (${this.streamType}) disconnected. Reconnecting...`)
            this.connectSocket(false, this.streamType)
          }, 1000)
        }
      )
    })
  }
}

import { defineStore } from 'pinia'
import { simseiApi, dispatchCheckAccessToken } from '@/residency/app-props'
import log from '@/residency/utils/log'
import { videoTypeEnums } from '@/residency/views/video/video-enums'
import { useAuthStore } from '@/residency/stores/auth'
import { useMeStore } from '@/residency/stores/me'
import { useVideoProcessingStore } from '@/residency/stores/video/video-processing'
import { usePausedUploadStore } from '@/residency/stores/video/paused-upload'

const tus = require('tus-js-client')

/**
 * Start an upload for a large File (>2GB or >1GB on bad networks).
 * Resolves with a Promise that contains Tus upload information when an upload
 * succeeds.
 */
async function uploadWithTus (file, uploadUrl, onUploadTaskCreated) {
  const authStore = useAuthStore()
  const uploadId = uploadUrl.split('/').pop()
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      endpoint: `${process.env.VUE_APP_BASE_API}`,
      uploadUrl: `${process.env.VUE_APP_BASE_API}${uploadUrl.split('/api')[1]}`,
      headers: {
        'Authorization': `Bearer ${authStore.tokenInfo.accessToken}`
      },
      chunkSize: window.Cypress ? 25600 : 3670016, // 25KB for Cypress, 3.5MB default
      retryDelays: [0, 1000, 3000],
      onShouldRetry: async (error, attempts, options) => {
        // if token is expired (status === 401), refresh it and retry
        if (error.originalResponse && error.originalResponse.getStatus() === 401) {
          log.warn(`Tus upload failed due to invalid token... (Total attempts ${attempts})`)
          // refresh token and retry
          try {
            const token = await dispatchCheckAccessToken()
            options.headers['Authorization'] = `Bearer ${token}`
            return true
          } catch (e) {
            log.warn(`Unexpected error while retrying: ${e} (Total attempts ${attempts})`)
            return false
          }
        } else {
          return false
        }
      },
      onError: (err) => {
        // Allow retries when access token is expired (status === 401).
        // reject in all other cases
        if (!err.originalResponse || err.originalResponse.getStatus() !== 401) {
          const { removeUploadProgress } = usePausedUploadStore()
          removeUploadProgress(uploadId)
          reject(err)
        }
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        // after each chunk is uploaded, we can get the total size and the uploaded size
        const pausedUploadStore = usePausedUploadStore()
        if (uploadId && pausedUploadStore.videoUploadProgressInfo.has(uploadId)) {
          pausedUploadStore.updateUploadProgress({
            uploadId,
            totalSize: bytesTotal,
            uploadedSize: bytesUploaded,
            secondsRemaining: pausedUploadStore.videoUploadProgressInfo.get(uploadId).secondsRemaining,
            isPaused: pausedUploadStore.videoUploadProgressInfo.get(uploadId).isPaused,
            cancelRequest: pausedUploadStore.videoUploadProgressInfo.get(uploadId).cancelRequest,
            uploadTask: pausedUploadStore.videoUploadProgressInfo.get(uploadId).uploadTask
          })
        }
      },
      onBeforeRequest: async (req) => {
        // ensure access token is valid before each request
        const token = await dispatchCheckAccessToken(20000)
        upload.options.headers['Authorization'] = `Bearer ${token}`
      },
      onAfterResponse: (req, res) => {
        // on response to PATCH request (after each chunk is uploaded), we can get the estimated time remaining
        // that was calculate by webservice
        const body = res.getBody()
        if (!body) {
          return
        }

        const latestEstimate = getProgressEstimate(body, uploadId)
        if (latestEstimate !== -1) {
          const pausedUploadStore = usePausedUploadStore()
          pausedUploadStore.updateUploadProgress({
            uploadId,
            totalSize: pausedUploadStore.videoUploadProgressInfo.get(uploadId).totalSize,
            uploadedSize: pausedUploadStore.videoUploadProgressInfo.get(uploadId).uploadedSize,
            secondsRemaining: latestEstimate,
            isPaused: pausedUploadStore.videoUploadProgressInfo.get(uploadId).isPaused,
            cancelRequest: pausedUploadStore.videoUploadProgressInfo.get(uploadId).cancelRequest,
            uploadTask: pausedUploadStore.videoUploadProgressInfo.get(uploadId).uploadTask
          })
        }
      },
      onSuccess: async () => {
        const uploadInfo = await simseiApi.get(`tus/upload/info?uri=${upload.url}`)
        if (uploadInfo && !upload.uploadInProgress && !upload.isExpired) {
          const { removeUploadProgress } = usePausedUploadStore()
          removeUploadProgress(uploadId)
          resolve(uploadInfo)
        } else {
          reject(new Error('Upload is not finished yet!'))
        }
      }
    })
    upload.start()
    onUploadTaskCreated(upload)
  })
}

function getProgressEstimate (resBody, uploadId) {
  if (!resBody) {
    throw new Error('No response body')
  }

  let secondsRemaining = -1
  try {
    secondsRemaining = Number(JSON.parse(resBody).secondsRemaining)
  } catch (e) {
    return -1
  }

  const { videoUploadProgressInfo } = usePausedUploadStore()
  if (uploadId && videoUploadProgressInfo.has(uploadId)) {
    return secondsRemaining
  } else {
    return -1
  }
}

function buildTusMetadata (videoAsmt, fileName, isSharingVideo, sharedVideoDescription, streamInfo) {
  // serialize video asmt metadata and encode it as base64 (as per Tus spec)
  const videoAsmtBase64 = Buffer.from(JSON.stringify(videoAsmt)).toString('base64')
  const filenameBase64 = Buffer.from(fileName).toString('base64')
  const isSharingVideoBase64 = Buffer.from(isSharingVideo.toString()).toString('base64')
  const streamInfoBase64 = Buffer.from(JSON.stringify(streamInfo)).toString('base64')

  // build tus upload metadata
  const filenameMetadata = `filename ${filenameBase64}`
  const isSharingVideoMetadata = `isSharingVideo ${isSharingVideoBase64}`
  const videoAsmtMetadata = `serializedVideoAsmtMetadata ${videoAsmtBase64}`
  const streamInfoMetadata = `streamInfo ${streamInfoBase64}`

  // add required tus metadata fields to the metadata string
  let csv = `${filenameMetadata},${videoAsmtMetadata},${isSharingVideoMetadata},${streamInfoMetadata}`

  // add optional tus metadata fields to the metadata string
  if (sharedVideoDescription) {
    // sharedVideoDescription is optional since a user can have an empty string as their sharedVideoDescription
    const sharedVideoDescriptionBase64 = Buffer.from(sharedVideoDescription).toString('base64')
    const sharedVideoDescriptionMetadata = `sharedVideoDescription ${sharedVideoDescriptionBase64}`

    csv += `,${sharedVideoDescriptionMetadata}`
  }

  return csv
}

export const useVideoAsmtStore = defineStore('videoAsmt', {
  state: () => ({
    peerVideos: [],
    instructorVideos: [],
    adminVideos: [],
    assessedVideos: [],
    mySimulationVideos: [],
    mySurgicalVideos: [],
    myOtherVideos: []
  }),
  actions: {
    async getSelfCreatedVideoAsmts (videoType) {
      const resp = await simseiApi.get(`/video/${videoType}/self`)
      this.updateSavedVideosTableState({
        videoType,
        videos: resp.data
      })
    },
    async getPeerVideoAsmts (videoType) {
      this.peerVideos = []
      const resp = await simseiApi.get(`video/${videoType}/unassessed/peer`)
      this.peerVideos = resp.data
    },
    async getInstructorVideoAsmsts (videoType) {
      const resp = await simseiApi.get(`/video/${videoType}/unassessed/instructor`)
      this.instructorVideos = resp.data
    },
    async getInstructorVideoAsmstsForGroup ({ videoType, groupId }) {
      const resp = await simseiApi.get(`/video/${videoType}/unassessed/instructor/${groupId}`)
      this.instructorVideos = resp.data
    },
    async getAdminVideoAsmts (programId) {
      const resp = await simseiApi.get('/video/admin', { params: { pid: programId } })
      this.adminVideos = resp.data
      return resp.data
    },
    async getVideoAsmtWithPlayerDetails (payload) {
      const response = await simseiApi.get(`/video/${payload.videoType}/full-details/${payload.videoAsmtId}`)
      return response.data
    },
    /**
    * Manual video upload called from the video library page
    */
    async uploadVideoAsmt ({
      videoAsmt,
      attachments,
      isSharingVideo,
      sharedVideoDescription,
      onUploadIdAllocated,
      usersToNotify
    }) {
      // use Tus creation extension to create a new upload URLs
      // https://tus.io/protocols/resumable-upload.html#creation
      if (attachments.length > 1) {
        throw new Error('Not implemented - expected only one attachment')
      }

      const file = attachments[0] // only one attachment is supported (laparoscopic stream)
      const streamInfo = {
        camera: file.camera,
        hasThumbnails: file.hasThumbnails
      }

      const csv = buildTusMetadata(videoAsmt, file.name, isSharingVideo, sharedVideoDescription, streamInfo)
      const res = await simseiApi.post('tus/upload', '', {
        headers: {
          'Tus-Resumable': '1.0.0',
          'Content-Type': 'application/offset+octet-stream',
          'Upload-Length': file.size,
          'Upload-Metadata': csv
        }
      })

      const tusLocation = res.headers.location
      const uploadId = tusLocation.split('/').pop()
      const { initializeUploadProgress } = usePausedUploadStore()
      const creationResponse = uploadWithTus(
        file,
        tusLocation,
        (upload) => {
          initializeUploadProgress({ uploadId, uploadTask: upload })
        }
      )
      onUploadIdAllocated(uploadId)

      const uploadInfo = (await creationResponse).data
      await this.finishVideoAsmtUpload({
        videoAsmt,
        uploadInfo,
        streamInfo,
        isSharingVideo,
        sharedVideoDescription,
        usersToNotify
      })
    },
    async finishVideoAsmtUpload ({
      videoAsmt,
      uploadInfo,
      streamInfo,
      isSharingVideo,
      sharedVideoDescription,
      usersToNotify
    }) {
      const info = {
        streamType: streamInfo.camera,
        hasThumbnails: streamInfo.hasThumbnails,
        id: uploadInfo.uploadId
      }

      const meStore = useMeStore()
      const userType = meStore.isInstructor ? 'faculty' : 'resident'

      await simseiApi.post(`video/${videoAsmt.videoType}/${userType}/upload/finish`, {
        videoAsmt,
        uploadInfo: info,
        isSharingVideo,
        sharedVideoDescription,
        usersToNotify,
        tusUploadId: uploadInfo.uploadId
      }, {
        headers: { 'Content-Type': 'application/json' }
      })

      const { fetchProcessingSubmissions } = useVideoProcessingStore()

      setTimeout(() => {
        // refresh the list of processing submissions after a delay
        fetchProcessingSubmissions()
      }, 1000)
    },
    async submitVideoAsmt (payload) {
      const resp = await simseiApi.patch(`video/${payload.videoType}/resident/submit/video-asmt`, payload.videoAsmt)
      return resp.data
    },
    async submitFacultyVideo (payload) {
      const resp = await simseiApi.patch(`video/${payload.videoAsmt.videoType}/faculty/submit/video-asmt`, payload.videoAsmt)
      return resp.data
    },
    async submitAndShareFacultyVideo (payload) {
      const resp = await simseiApi.patch(`video/${payload.videoAsmt.videoType}/faculty/submit-share/video-asmt`, payload)
      return resp.data
    },
    deleteVideoAsmt (videoAsmtId) {
      return simseiApi.delete(`/video/${videoAsmtId}`)
    },
    setVideoLibraryFilter (filterValue) {
      this.videoLibraryFilter = filterValue
    },
    async fetchAssessedVideosForUser (userGroupId) {
      const res = await simseiApi.get(`/video/assessed-videos/${userGroupId}`)
      this.assessedVideos = res.data
    },
    acknowledgeNewAssessedVideo (asmtId) {
      return simseiApi.post(`/video/acknowledge-assessed-video/${asmtId}`)
    },
    updateVideoAsmtActivity ({ videoId, newActivityId }) {
      return simseiApi.patch(`/video/simulation/update-activity?videoId=${videoId}&newActivityId=${newActivityId}`)
    },
    updateSurgicalApproachProcedure (payload) {
      return simseiApi.patch('/video/surgical/update-approach-procedure', payload)
    },
    updateOtherTitle (payload) {
      return simseiApi.patch('/video/other/update-title', payload)
    },
    savedVideoTableFetchProcessingSubmissions ({
      newProcessingSubmissions, oldProcessingSubmissions, videoType
    }) {
      if (newProcessingSubmissions.length < oldProcessingSubmissions.length) {
        const fetchSubmissions = async () => {
          await this.getSelfCreatedVideoAsmts(videoType)
        }

        fetchSubmissions()
        setTimeout(() => {
          // try again after a delay in case it takes a bit for submission
          // to move from processing to saved
          fetchSubmissions()
        }, 2500)
      }
    },
    clearSavedVideosTableState (videoType) {
      this.updateSavedVideosTableState({
        videoType,
        videos: []
      })
    },
    updateSavedVideosTableState ({ videoType, videos }) {
      switch (videoType) {
        case videoTypeEnums.SIMULATION:
          this.mySimulationVideos = videos
          break
        case videoTypeEnums.SURGICAL:
          this.mySurgicalVideos = videos
          break
        case videoTypeEnums.OTHER:
          this.myOtherVideos = videos
          break
        default:
          this.$log.error('Unknown video type found', videoType)
      }
    },
    uploadWithTus ({ file, uploadUrl, onUploadTaskCreated }) {
      return uploadWithTus(file, uploadUrl, onUploadTaskCreated)
    },
    addInstructorVideo (video) {
      this.instructorVideos.push(video)
    },
    addPeerVideo (video) {
      this.peerVideos.push(video)
    }
  }
})

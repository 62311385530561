<template>
  <div class="text-area-container">
    <textarea
      ref="textarea"
      :id="id"
      :value="modelValue"
      :autofocus="autofocus"
      :disabled="disabled"
      :required="required"
      :style="textAreaStyle"
      :placeholder="placeholder"
      :class="['text-area', { 'no-resize': noResize, 'border': outlined }]"
      :rows="rows"
      :data-cy="dataCy"
      :maxlength="maxlength"
      :spellcheck="spellcheck"
      data-testid="base-textarea"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @click="$emit('click')"
    ></textarea>
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
    <CharactersRemainingLabel
      v-if="maxlength"
      :modelValue="modelValue"
      :maxlength="maxlength"
    />
  </div>
</template>

<script>
import CharactersRemainingLabel from '@/components/inputs/components/CharactersRemainingLabel.vue'
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation.js'

export default {
  components: {
    CharactersRemainingLabel,
    InputErrorBucket
  },
  props: {
    modelValue: {
      required: false
    },
    id: {
      type: String,
      requird: false
    },
    rules: {
      type: Function,
      required: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    noResize: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: [String, Number],
      required: false
    },
    rows: {
      type: [String, Number],
      required: false,
      default: 3
    },
    minHeight: {
      type: Number,
      required: false
    },
    maxRows: {
      type: Number,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    hideDetails: {
      type: [String, Boolean],
      required: false,
      default: 'auto'
    },
    placeholder: {
      type: String,
      required: false
    },
    formatter: {
      type: Function,
      required: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    fontSize: {
      type: [String, Number],
      required: false
    },
    blurHandler: {
      type: Function,
      required: false
    },
    focusHandler: {
      type: Function,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    },
    maxlength: {
      type: [String, Number],
      required: false
    },
    spellcheck: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'focus', 'blur', 'click'],
  setup (props) {
    const {
      errorMessage,
      setAllowValidation,
      validate,
      resetValidation,
      hasValidationRules,
      reset
    } = useInputValidation(props)
    return { errorMessage, setAllowValidation, validate, resetValidation, hasValidationRules, reset }
  },
  computed: {
    styleWidth () {
      if (!this.width) return ''
      if (isNaN(this.width)) return `width:${this.width};` // cases such as '3rem', '10%', etc.
      return `width:${this.width}px;`
    },
    styleHeight () {
      if (!this.textAreaHeight) return `height:${this.maxHeight}px;`
      return `min-height:${this.textAreaHeight}px;`
    },
    styleFontSize () {
      if (!this.fontSize) return ''
      if (isNaN(this.fontSize)) return `font-size:${this.fontSize};`
      return `font-size:${this.fontSize}px;`
    },
    textAreaStyle () {
      return `${this.styleWidth} ${this.styleHeight} ${this.styleFontSize}`
    }
  },
  methods: {
    async handleInput (event) {
      event.target.value = this.formatter ? this.formatter(event.target.value) : event.target.value
      this.$emit('update:modelValue', event.target.value)
      await this.$nextTick()
      this.validate()
      this.setHeight(event.target)
    },
    handleFocus (event) {
      event.target.value = this.focusHandler ? this.focusHandler(event.target.value) : event.target.value
      this.$emit('focus', event.target.value)
      this.setHeight(event.target)
    },
    handleBlur (event) {
      event.target.value = this.blurHandler ? this.blurHandler(event.target.value) : event.target.value
      this.$emit('blur', event.target.value)
      this.setHeight(event.target)
    },
    setHeight ({ scrollHeight, clientHeight }) {
      this.textAreaHeight = Math.max(scrollHeight + 2, clientHeight)
      if (this.maxHeight && this.maxHeight < this.textAreaHeight) {
        this.textAreaHeight = this.maxHeight
      }
      if (this.minHeight && this.minHeight > this.textAreaHeight) {
        this.textAreaHeight = this.minHeight
      }
    }
  },
  data () {
    return {
      textAreaHeight: null
    }
  },
  mounted () {
    this.setHeight(this.$refs.textarea)
    if (this.maxRows) {
      this.rules.push(v => !v || v.split(/\r\n|\r|\n/).length <= this.maxRows || 'Field must be under ' + this.maxRows + ' lines')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.no-resize {
  resize: none;
}

.text-area {
  background-color: $surface-color;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 4px;
  border-radius: $border-radius;
  outline: 0px none transparent;
  border: 1px solid $border-grey;

  &:hover {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid $primary-digital-teal-default;
  }

  &:focus {
    border: 1px solid $primary-digital-teal-default;
  }
}

.border {
  border: 1px solid $border-grey;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>

<template>
  <div :class="['base-card', {'card-border-shadow': borderShadow}]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    borderShadow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.base-card {
  border-radius: 8px;
  background-color: $surface-color;
  overflow: hidden;
}

.card-border-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
</style>

<template>
  <Transition name="slide">
    <slot></slot>
  </Transition>
</template>

<script>
/**
 * The `BaseTabWindow` component is used with the tabs.
 * It provides the baseline functionality for transitioning content between tab panes.
 */
export default {
  name: 'BaseTabWindow'
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>

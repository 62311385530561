import log from '@/residency/utils/log'

export const getMimeType = () => {
  if (typeof MediaRecorder === 'undefined') {
    log.error(`MediaRecorder is not supported in this browser: ${navigator.userAgent}`)
    throw new Error('Failed to set up media recorder. This browser may not be supported.')
  }

  // Available mime types in order of priority
  // TODO: the mime types we use for ai assessments might be different,
  // need to check on this.
  const mimes = [
    'video/mp4;codecs=h264',
    'video/webm;codecs=h264',
    'video/webm;codecs=vp8'
  ]

  for (let i = 0; i < mimes.length; i++) {
    if (MediaRecorder.isTypeSupported(mimes[i])) {
      return mimes[i]
    }
  }

  log.error(`No compatible mime type found for user agent: ${navigator.userAgent}`)
  throw new Error('Failed to set up media type for recording. This browser may not be supported.')
}

export function getVideoExtension (mimeType) {
  let fileExt = 'webm'
  if (mimeType.includes('mp4')) {
    fileExt = 'mp4'
  }
  return fileExt
}

import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import VideoRecordStream from '@/residency/utils/video-record-stream'

export const useVideoRecorderStore = defineStore('videoRecorder', {
  state: () => ({
    /**
     * An object that contains active VideoRecordStream instances
     * keyed by the stream type. Streams are kept alive here until
     * we receive a PROCESSING_DONE message from the server. This
     * allows us to start recording a new stream of one type while
     * while the previous stream of a different type is finishing
     * in the background.
     */
    streams: {}
  }),
  actions: {
    /**
    * Create a video assessment row in the database and return its ID
    */
    async createVideoAsmt (payload) {
      const resp = await simseiApi.post(`/video/${payload.videoType}/start-record`, payload.videoInfo)
      const uuid = resp.data
      return uuid
    },
    deleteVideoAsmt (videoAsmtId) {
      return simseiApi.delete(`/video/${videoAsmtId}`)
    },
    createStream (streamType, deviceId, asmtId) {
      this.streams[streamType] = new VideoRecordStream(streamType, deviceId, asmtId)
      return this.streams[streamType]
    },
    getStream (streamType) {
      return this.streams[streamType]
    },
    deleteStreamType (streamInfo) {
      if (this.streams[streamInfo.streamType] && this.streams[streamInfo.streamType].id === streamInfo.streamId) {
        this.deleteStream(this.streams[streamInfo.streamType])
      }
    },
    deleteStream (stream) {
      stream.destroy()
      delete this.streams[stream.getStreamType()]
    },
    submitTimedOnlyAsmt (timeElapsed, activityId, userGroupId) {
      const timeElapsedInSeconds = Math.floor(timeElapsed / 1000)
      return simseiApi.post('/timed-only-asmt/create', {
        activityId: activityId,
        length: timeElapsedInSeconds,
        userGroupId: userGroupId
      })
    }
  }
})
